.css-serial {
  counter-reset: serial-number; /* Set the serial number counter to 0 */
}
.css-serial td:first-child:before {
  counter-increment: serial-number; /* Increment the serial number counter */
  content: counter(serial-number); /* Display the counter */
}

.admin {
  display: flex;
  gap: 20px;
  position: relative;
}

table tr:nth-of-type(odd) {
  background: rgb(223, 222, 222);
}

.openmodal {
  height: 50px;
  border: none;
  background-color: #d9232a;
  color: white;
  font-size: 20px;
}
.inputs {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 20px;
  padding: 8px;
}

.loginButton {
  background-color: #ffffff;
  color: black;
  border-radius: 10px;
  font-size: 25px;

  font-weight: bold;
}

.overlay {
  background-color: gray;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.onclose {
  position: absolute;
  top: 20px;
  left: 20px;
}
.modal {
  background-color: white;
  min-height: 500px;
  max-height: 600px;
  width: 800px;
  z-index: 2;
  padding: 20px;
  overflow: scroll;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

.winners {
  margin: 0;
  font-size: 20px;
}
.winners-cont {
  display: flex;
  width: 380px;
}

.shufflebtn {
  background-color: #d9232a;
  color: white;
  border-radius: 10px;
  font-size: 35px;
  letter-spacing: 0.07em;
  font-weight: bold;
  cursor: pointer;
}

.shufflenumber {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 20px;
  padding: 8px;
}

.shf {
  display: flex;
  flex-direction: row !important;
  gap: 20px !important;
}

.xdd {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  gap: 20px;
}
