.nav-holder {
  width: 370px;
  height: 150px;
  margin: 20px 0;
}

.nav-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;

  img {
    width: 75%;
    height: 100%;
  }
}

.nav-buttons {
  display: flex;
  justify-content: space-between;
  gap: 23px;
}

.nav-button {
  width: 125px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  border-color: #ffffff;
  font-size: 20px;
  border: 0px;
}

@media screen and (max-width: 370px) {
  .nav-holder {
    flex-direction: column;
    margin-bottom: 120px;
    gap: 20px;
    width: 300px;
  }
}
