.modal-holder {
  position: absolute;
  width: 300px;
  border: 1px solid black;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;

  img {
    height: 550px;
    border: 1px solid black;
  }
}

.closebutton {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  button {
    height: 30px;
    font-size: 15px;
    cursor: pointer;
    width: 30px;
    margin-top: 15px;
  }
}
