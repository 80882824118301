.form {
  width: 350px;
  background-color: white;
  padding: 10px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  button {
    background-color: #d9232a;
    color: white;
    border-radius: 10px;
    font-size: 35px;
    letter-spacing: 0.07em;
    font-weight: bold;
  }
}

.submitForm {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.inputs {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 20px;
  padding: 8px;
}
.inputs-phone {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 20px;
  padding: 8px;
  width: 288px;
}
.number {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 20px;
  padding: 3px 10px;
}

@media screen and (max-width: 400px) {
  .form {
    width: 300px;
  }

  .inputs {
    width: 270px;
  }
  form {
    width: 280px;
  }

  .inputs-phone {
    width: 95%;
    border-top-left-radius: 0;
  }
  .number {
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
  }
}
