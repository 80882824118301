.main {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .main {
    background-image: url('');
    background-color: #d9232a;
  }
}
