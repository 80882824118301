.bottom {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 75px;
}

.bottom-buttons {
  width: 80px;
  height: 50px;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  border-color: #ffffff;
  font-size: 15px;
  border: 0px;
}


@media screen and (max-width: 450px) {
  .bottom-buttons {
    font-size: 13px;
  }
}
