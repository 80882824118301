.holder {
  background-color: #d9232a;
  min-width: 450px;
  max-width: 450px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  flex: 1;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  .holder {
    min-width: 300px;
  }
}
