.bg {
  min-height: 100vh;
  min-width: 100vw;
  background-image: url("../assets/nyeremenyjatek/hatterek/Bg_nyeremeny_2560x1400px.jpg");
  background-size: cover;
  background-repeat: repeat;
  background-position: center center;
}
.rules-button {
  width: 100px;
  height: 35px;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  border-color: #ffffff;
  font-size: 20px;
  border: 0px;
  margin-left: 10px;
  margin-top: 10px;
}

.prize-images {
  img {
    margin: 10px;
    width: 400px;
  }
}

.imgHolder {
  padding: 0 20px;
  background-image: url("../assets/nyeremenyjatek/hatterek/Bg_nyeremeny_2560x1400px.jpg");
  background-size: cover;
  background-repeat: repeat;
  background-position: center center;
  min-height: 100vh;
  min-width: 100vw;

  h2 {
    background-color: white;
    display: table;
    border-radius: 20px;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 460px) {
  .bg .imgHolder {
    background-color: #d9232a;
    background-image: url("");
  }

  .prize-images {
    img {
      margin: 10px;
      width: 250px;
    }
  }
  .imgHolder {
    padding: 0;

    h2 {
      background-color: transparent;
      font-size: 18px;
      text-align: center;
      width: 250px;
    }
  }
  .images {
    img {
      width: 250px !important;
    }
  }
  .divider {
    background-color: black;
    height: 5px;
    width: 250px;
    margin-left: 10px;
    margin-top: 20px;
  }
}

.images {
  img {
    width: 450px;
  }
}
