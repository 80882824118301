@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
.css-serial{counter-reset:serial-number}.css-serial td:first-child:before{counter-increment:serial-number;content:counter(serial-number)}.admin{display:flex;grid-gap:20px;gap:20px;position:relative}table tr:nth-of-type(odd){background:#dfdede}.openmodal{height:50px;border:none;background-color:#d9232a;color:#fff;font-size:20px}.inputs{border:1px solid rgba(0,0,0,.5);border-radius:10px;font-size:20px;padding:8px}.loginButton{background-color:#fff;color:#000;border-radius:10px;font-size:25px;font-weight:bold}.overlay{background-color:gray;position:absolute;width:100vw;height:100vh;display:flex;justify-content:center;align-items:center;z-index:3}.onclose{position:absolute;top:20px;left:20px}.modal{background-color:#fff;min-height:500px;max-height:600px;width:800px;z-index:2;padding:20px;overflow:scroll}.modal div{display:flex;flex-direction:column;align-items:center;justify-content:center;grid-gap:10px;gap:10px}.winners{margin:0;font-size:20px}.winners-cont{display:flex;width:380px}.shufflebtn{background-color:#d9232a;color:#fff;border-radius:10px;font-size:35px;letter-spacing:.07em;font-weight:bold;cursor:pointer}.shufflenumber{border:1px solid rgba(0,0,0,.5);border-radius:10px;font-size:20px;padding:8px}.shf{display:flex;flex-direction:row !important;grid-gap:20px !important;gap:20px !important}.xdd{display:flex;flex-direction:row !important;align-items:flex-start !important;justify-content:flex-start !important;grid-gap:20px;gap:20px}
.main{width:100%;display:flex;justify-content:center}@media screen and (max-width: 1000px){.main{background-image:url("");background-color:#d9232a}}
.holder{background-color:#d9232a;min-width:450px;max-width:450px;min-height:100vh;display:flex;align-items:center;flex-direction:column;justify-content:space-between;grid-gap:50px;gap:50px;flex:1 1;position:relative;z-index:1}@media screen and (max-width: 600px){.holder{min-width:300px}}
.form{width:350px;background-color:#fff;padding:10px;height:100%;border-radius:10px;display:flex;justify-content:space-around;align-items:center;flex-wrap:wrap;position:relative}.form button{background-color:#d9232a;color:#fff;border-radius:10px;font-size:35px;letter-spacing:.07em;font-weight:bold}.submitForm{display:flex;flex-direction:column;grid-gap:23px;gap:23px}.inputs{border:1px solid rgba(0,0,0,.5);border-radius:10px;font-size:20px;padding:8px}.inputs-phone{border:1px solid rgba(0,0,0,.5);border-radius:10px;border-top-left-radius:0;border-bottom-left-radius:0;font-size:20px;padding:8px;width:288px}.number{border:1px solid rgba(0,0,0,.5);border-radius:10px;border-top-right-radius:0;border-bottom-right-radius:0;font-size:20px;padding:3px 10px}@media screen and (max-width: 400px){.form{width:300px}.inputs{width:270px}form{width:280px}.inputs-phone{width:95%;border-top-left-radius:0}.number{border-bottom-left-radius:0;border-top-right-radius:10px}}
.modal-holder{position:absolute;width:300px;border:1px solid #000;grid-gap:10px;gap:10px;padding:10px;border-radius:10px;background-color:#fff;display:flex;flex-direction:column}.modal-holder img{height:550px;border:1px solid #000}.closebutton{display:flex;justify-content:space-between;grid-gap:20px;gap:20px}.closebutton button{height:30px;font-size:15px;cursor:pointer;width:30px;margin-top:15px}
.nav-holder{width:370px;height:150px;margin:20px 0}.nav-image{display:flex;justify-content:center;align-items:center;width:100%;width:-webkit-fill-available;width:fill-available;height:100%;height:-webkit-fill-available;height:fill-available}.nav-image img{width:75%;height:100%}.nav-buttons{display:flex;justify-content:space-between;grid-gap:23px;gap:23px}.nav-button{width:125px;height:50px;background-color:#fff;border-radius:10px;cursor:pointer;border-color:#fff;font-size:20px;border:0px}@media screen and (max-width: 370px){.nav-holder{flex-direction:column;margin-bottom:120px;grid-gap:20px;gap:20px;width:300px}}
.bottom{width:100%;display:flex;justify-content:space-evenly;margin-top:75px}.bottom-buttons{width:80px;height:50px;background-color:#fff;border-top-left-radius:10px;border-top-right-radius:10px;cursor:pointer;border-color:#fff;font-size:15px;border:0px}@media screen and (max-width: 450px){.bottom-buttons{font-size:13px}}
.left{flex:1 1}.left{background-image:url(/static/media/ps_max_bg_2560x1400px.d65bcc76.jpg);background-size:cover;background-repeat:no-repeat}@media screen and (max-width: 1000px){.left{display:none}}
.bg{min-height:100vh;min-width:100vw;background-image:url(/static/media/Bg_nyeremeny_2560x1400px.3db2bcf3.jpg);background-size:cover;background-repeat:repeat;background-position:center center}.rules-button{width:100px;height:35px;background-color:#fff;border-radius:10px;cursor:pointer;border-color:#fff;font-size:20px;border:0px;margin-left:10px;margin-top:10px}.prize-images img{margin:10px;width:400px}.imgHolder{padding:0 20px;background-image:url(/static/media/Bg_nyeremeny_2560x1400px.3db2bcf3.jpg);background-size:cover;background-repeat:repeat;background-position:center center;min-height:100vh;min-width:100vw}.imgHolder h2{background-color:#fff;display:table;border-radius:20px;padding:5px 10px}@media screen and (max-width: 460px){.bg .imgHolder{background-color:#d9232a;background-image:url("")}.prize-images img{margin:10px;width:250px}.imgHolder{padding:0}.imgHolder h2{background-color:transparent;font-size:18px;text-align:center;width:250px}.images img{width:250px !important}.divider{background-color:#000;height:5px;width:250px;margin-left:10px;margin-top:20px}}.images img{width:450px}
body{margin:0;width:100%;padding:0}*{font-family:"Oswald",sans-serif}*:focus{outline:none}input[type=file]{display:none}.custom-file-upload{border:1px solid #000;border-radius:10px;display:inline-block;text-align:center;background-color:#fff;padding:6px 12px;cursor:pointer;font-size:20px}a{color:blue;text-decoration:none}input{height:20px}.checkinput{margin-right:10px;width:25px;height:25px;background-color:#fff;border-radius:50%;vertical-align:middle;border:1px solid #000;-webkit-appearance:none;outline:none;cursor:pointer}.checkinput:checked{background-color:#d9232a}.checkinput:disabled{background-color:gray}.checkboxlabel{font-size:17px;width:300px}
