.left {
  flex: 1;
}

.left {
  background-image: url('../assets/max/ps_max_bg_2560x1400px.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1000px) {
  .left {
    display: none;
  }
}
