@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

body {
  margin: 0;
  width: 100%;
  padding: 0;
}

* {
  font-family: "Oswald", sans-serif;
}
*:focus {
  outline: none;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  background-color: white;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 20px;
}

a {
  color: blue;
  text-decoration: none;
}

input {
  height: 20px;
}
.checkinput {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkinput:checked {
  background-color: #d9232a;
}

.checkinput:disabled {
  background-color: grey;
}

.checkboxlabel {
  font-size: 17px;
  width: 300px;
}
